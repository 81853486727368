import {React, useEffect } from 'react'
import { FaBiohazard } from 'react-icons/fa'
import { motion, useScroll, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


export default function Story6Caption() {
  const {ref, inView} = useInView();
  const IntroAnimation = useAnimation();
  const IntroTextAnimation = useAnimation();

  useEffect(() => {
    
    if(inView){
      IntroAnimation.start({
        y: '0',
        opacity: 1,
        scale: [.8, 1, .8],
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0px',
        scale: 1,
        opacity: 1,
        transition: {
          type: 'tween', duration: .5
        }
      });
    } if (!inView){
      IntroAnimation.start({
        y: '0',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    }
  }, [inView]);


  return (
    <section ref={ref}>
      <div className="">
        <div className=''>
            <div className=''>
                 
              <motion.div className='px-4 StoryCaption fontArt' animate={IntroTextAnimation}>                            
        
              <p>The elites are still sitting comfortably while the rest of the world literally burns. They hold the last stockpiles of edible food and water. They are willing to make small trades in exchange for the finest of art. Specifically Picassos, Da Vincis, And Fine Art Mfers</p>
              
              </motion.div>
            </div>
 
        </div>
      </div>
    </section>
  )
}

