import {React, useEffect } from 'react'
import { FaBiohazard } from 'react-icons/fa'
import { motion, useScroll, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


export default function Story8Caption() {
  const {ref, inView} = useInView();
  const IntroAnimation = useAnimation();
  const IntroTextAnimation = useAnimation();

  useEffect(() => {
    
    if(inView){
      IntroAnimation.start({
        y: '0',
        opacity: 1,
        scale: [.8, 1, .8],
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0px',
        scale: 1,
        opacity: 1,
        transition: {
          type: 'tween', duration: .5
        }
      });
    } if (!inView){
      IntroAnimation.start({
        y: '0',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    }
  }, [inView]);


  return (
    <section ref={ref}>
      <div className="">
        <div className=''>
            <div className=''>
                 
              <motion.div className='px-4 StoryCaption fontArt' animate={IntroTextAnimation}>                            
        
              <p>Hearing this announcement, people flocked to the museums in droves in hopes of acquiring a fine art piece that could be traded for food or water.</p>
              
              </motion.div>
            </div>
 
        </div>
      </div>
    </section>
  )
}

