import * as React from "react";
import styled from "styled-components";
import Web3 from "web3";
import "../HomepageComponent/Homepage.css";

import { createAlchemyWeb3 } from "@alch/alchemy-web3";

import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

import { Slider } from "@mui/material";
// import { convertUtf8ToHex } from "@walletconnect/utils";
// @ts-ignore
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnect from "@walletconnect/web3-provider";
// @ts-ignore
// import Torus from "@toruslabs/torus-embed";
// @ts-ignore
import WalletLink from "walletlink";

import Button from "./Button";
import Column from "./Column";
import Wrapper from "./Wrapper";
import Modal from "./Modal";
import Loader from "./Loader";
// import Header from "./Header";
// import ModalResult from "./ModalResult";
import ConnectButton from "./ConnectButton";

import {
  ellipseAddress,
  getChainData,
  // formatMintTransaction
} from "./helpers/utilities";
import { IAssetData } from "./helpers/types";
import { fonts } from "./styles";
import { MINT_ACTION } from "./constants";
import { callMint, callPresaleMint } from "./helpers/web3";
import { getMintCount } from "./helpers/mintCount";

import "./App.css";
import whitelist from "./whitelist";

const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 650px; //vh100
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SLanding = styled(Column)`
  height: 150px;
  //   padding-top: 60px;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
  color: #050a17 !important;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    // padding-top: 30px;
  }
`;

const STestButtonContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STestButton = styled(Button)`
  //   border-radius: 8px;
  //   font-size: ${fonts.size.medium};
  height: 150px;
  width: 100%;
  max-width: 175px;
  background-color: rgba(0, 0, 0, 0);

  //   background-image: url('/assets/images/Mint Button.png');
  background-size: 100% 100%;
  box-shadow: none !important;
`;

const presaleList = whitelist;

const ALCHEMY_ID = "06TjEVJYYTgNryGP7Awp9F9HqnccVp0z";
function sliderValueText(value: number) {
  return `${value}`;
}

function getMerkleTree() {
  const leafNodes = presaleList.map((addr) => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  console.log("root: 0x" + merkleTree.getRoot().toString("hex"));
  return merkleTree;
}

const nftContractAddress = "0xF0031782d37819F8E209C4421C774eD1f6163052";
const alchemyWeb3 = createAlchemyWeb3(
  `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`
); //https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_ID}`);

const ABI: any = [
  {
    inputs: [
      { internalType: "string", name: "_name", type: "string" },
      { internalType: "string", name: "_symbol", type: "string" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "AccessDenied", type: "error" },
  { inputs: [], name: "ApprovalCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "ApprovalQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "BadInput", type: "error" },
  { inputs: [], name: "BalanceQueryForZeroAddress", type: "error" },
  { inputs: [], name: "EthValue", type: "error" },
  { inputs: [], name: "MaxSupply", type: "error" },
  { inputs: [], name: "MintERC2309QuantityExceedsLimit", type: "error" },
  { inputs: [], name: "MintLimit", type: "error" },
  { inputs: [], name: "MintToZeroAddress", type: "error" },
  { inputs: [], name: "MintZeroQuantity", type: "error" },
  { inputs: [], name: "OwnerQueryForNonexistentToken", type: "error" },
  { inputs: [], name: "OwnershipNotInitializedForExtraData", type: "error" },
  { inputs: [], name: "Paused", type: "error" },
  { inputs: [], name: "TransferCallerNotOwnerNorApproved", type: "error" },
  { inputs: [], name: "TransferFromIncorrectOwner", type: "error" },
  { inputs: [], name: "TransferToNonERC721ReceiverImplementer", type: "error" },
  { inputs: [], name: "TransferToZeroAddress", type: "error" },
  { inputs: [], name: "URIQueryForNonexistentToken", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "fromTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "toTokenId",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
    ],
    name: "ConsecutiveTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  { stateMutability: "payable", type: "fallback" },
  {
    inputs: [],
    name: "admin",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "adminAccess",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "mintQty", type: "uint256" },
      { internalType: "address", name: "recipient", type: "address" },
    ],
    name: "devMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256[]", name: "quantity", type: "uint256[]" },
      { internalType: "address[]", name: "recipient", type: "address[]" },
    ],
    name: "devMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "freezeMetadata",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "frozen",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "giftClaimed",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "mintQty", type: "uint256" },
      { internalType: "bytes32[]", name: "_merkleProof", type: "bytes32[]" },
    ],
    name: "mferEarlyMint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "mintQty", type: "uint256" },
      { internalType: "bytes32[]", name: "_merkleProof", type: "bytes32[]" },
    ],
    name: "mferEarlyMintGift",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "mferMerkleRoot",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "mintQty", type: "uint256" }],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "mintQty", type: "uint256" },
      { internalType: "bytes32[]", name: "_merkleProof", type: "bytes32[]" },
    ],
    name: "mintMferGift",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "presale",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "newSupply", type: "uint256" }],
    name: "reduceMaxSupply",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "sale",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_cost", type: "uint256" }],
    name: "setCost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "root", type: "bytes32" }],
    name: "setMerkleRoot",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "toggleAdminAccess",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "togglePresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "toggleSale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
] as any;
interface MintCountProps {
  totalMinted: number | null;
}

function MintCount({ totalMinted }: MintCountProps) {
  // const [totalMinted, setTotalMinted] = React.useState(null);

  // React.useEffect(() => {
  //   async function fetchMintCount() {
  //     const mintCount = await getMintCount();
  //     setTotalMinted(mintCount);
  //   }
  //   fetchMintCount();
  // }, []);

  return (
    <div className="text-center w-full">
      <p className="font text-3xl font-semibold w-full">
        Minted NFTs: <br/><br/> <span className="5xl bg-[#510c0c] py-4 px-8 text-white rounded-full">{totalMinted !== null ? totalMinted : "Loading..."} / 4200</span>
      </p>
    </div>
  );
}

const nftContract = new alchemyWeb3.eth.Contract(ABI, nftContractAddress);
const sliderMarks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 5,
    label: "5+🎁",
  },
  {
    value: 10,
    label: "10+🎁🎁",
  },
];

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
  mintQty: any;
  buttonText: string;
  presalePartner: any;
  cost: number;
  // checkingWallet: boolean;
  discountMints: number;
  mintMax: number;
  merkleTree: any;
  presaleData: any;
  extraMint: boolean;
  soonMessage: boolean;
  showMessageModal: boolean;
  showSoldoutModal: boolean;
  showPresaleErrorModal: boolean;
  showGiftModal: boolean;
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  mintQty: 1,
  buttonText: "CONNECT",
  presalePartner: null,
  cost: 0.022,
  // checkingWallet: true
  discountMints: 5,
  mintMax: 5,
  merkleTree: getMerkleTree(),
  presaleData: null,
  extraMint: false,
  soonMessage: false,
  showMessageModal: false,
  showSoldoutModal: false,
  showPresaleErrorModal: false,
  showGiftModal: false,
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  // public state: IAppState;
  public state: IAppState & { totalMinted: number | null };

  public mintQty: any; // removed from SM
  constructor(props: any) {
    super(props);
    this.mintQty = 1;
    this.state = {
      ...INITIAL_STATE,
      totalMinted: null,
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions(),
      // theme: "dark"
      theme: {
        background: "rgb(39, 49, 56)",
        main: "rgb(240, 240, 240)",
        secondary: "rgb(185, 185, 185)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)",
      },
    });
  }

  public componentDidMount() {
    // console.log("componentDidMount: ", this.state);

    if (this.web3Modal.cachedProvider) {
      // console.log("componentDidMount -> onConnect")
      this.onConnect();
    }
    this.fetchMintCount();
  }

  public fetchMintCount = async () => {
    const mintCount = await getMintCount();
    this.setState({ totalMinted: mintCount });
  }

  public getPresaleData(address: any): any {
    const merkleProof = this.state.merkleTree.getHexProof(keccak256(address));

    return merkleProof;
  }

  public handlePresaleSetup = async (address: string) => {
    var presaleData: string[];
    address = alchemyWeb3.utils.toChecksumAddress(address);
    if (presaleList.includes(address)) {
      presaleData = this.getPresaleData(address);
      console.log("merkle data: ", presaleData);
      const claimed = await nftContract.methods.giftClaimed(address).call();
      console.log("gift claimed: ", claimed);
      this.setState({ presaleData, extraMint: !claimed });
      if (claimed == false) {
        this.toggleGiftModal();
      }
    } else {
      this.setState({ presaleData: null, extraMint: false });
    }
  };

  public onConnect = async () => {
    try {
      this.setState({ checkingWallet: true });
      const provider = await this.web3Modal.connect();

      await this.subscribeProvider(provider);

      await provider.enable();
      const web3: any = initWeb3(provider);
      const accounts = await web3.eth.getAccounts();
      const address = alchemyWeb3.utils.toChecksumAddress(accounts[0]);
      const networkId = await web3.eth.net.getId();
      const chainId = await web3.eth.chainId();

      this.handlePresaleSetup(address);
      await this.setState({
        web3,
        provider,
        connected: true,
        address,
        chainId,
        networkId,
        // presaleData
        checkingWallet: false,
      });

      const btnText = document.getElementById("connect-btn-text");
      const btn = document.getElementById("connect-btn");

      this.setState({ buttonText: ellipseAddress(accounts[0]) });
      if (btn) {
        btn.innerText = ellipseAddress(accounts[0]);
      }

      if (btn) {
        btn.style.backgroundColor = "rgba(0,0,0,0)"; //"#b74848"//"red"
      }
      // setTimeout(() => this.setState({checkingWallet: false}),500)

      // GET WHITELIST STATUS OR PARTNER NFTS
    } catch (error) {
      console.log("123");
      console.log(error);
      this.resetApp();
    }
  };

  public async getDiscountMints(address: any) {
    const discountMints = await nftContract.methods
      .discountMints(address)
      .call();
    console.log("discountMints: ", discountMints);
    return discountMints;
  }

  public onSliderUpdate = (event: any, value: any) => {
    this.setState({ mintQty: value });
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => {
      console.log("disconnect");
      this.resetApp();
    });

    provider.on("disconnect", () => {
      console.log("disconnect");
      this.resetApp();
    });

    provider.on("accountsChanged", async (accounts: string[]) => {
      console.log("account changed - accounts ", accounts);
      const address = accounts[0];
      this.handlePresaleSetup(address);
      this.setState({ buttonText: ellipseAddress(address), address: address });
      const btn = document.getElementById("connect-btn");
      if (btn) {
        btn.innerText = ellipseAddress(address);
      }
    });

    provider.on("chainChanged", async (chainId: number) => {
      console.log("chain changed");
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await this.setState({ chainId, networkId });
    });

    provider.on("networkChanged", async (networkId: number) => {
      console.log("network changed");
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();
      await this.setState({ chainId, networkId });
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnect,
        options: {
          rpc: {
            1: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
            5: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
          },
        },
      },
      "custom-walletlink": {
        display: {
          logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
          name: "Coinbase",
          description: "Connect to Coinbase Wallet (not Coinbase App)",
        },
        options: {
          jsonRpcUrl: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
          appName: "fine art mfers",
          networkUrl: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
          rpc: {
            1: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
            5: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
          },
          chainId: 1,
        },
        package: WalletLink,
        connector: async (_: any, options: any) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
    };
    return providerOptions;
  };

  public toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  public toggleMessageModal = () => {
    this.setState({ showMessageModal: !this.state.showMessageModal });
  };

  public toggleSoldoutModal = () => {
    this.setState({ showSoldoutModal: !this.state.showSoldoutModal });
  };

  public togglePresaleErrorModal = () => {
    this.setState({ showPresaleErrorModal: !this.state.showPresaleErrorModal });
  };

  public toggleGiftModal = () => {
    this.setState({ showGiftModal: !this.state.showGiftModal });
  };

  public startMint = async (functionSig: string) => {
    const saleStarted = await nftContract.methods.sale().call();
    const presaleStarted = await nftContract.methods.presale().call();
    // console.log("PAUSED = ", paused)
    if (!saleStarted && !presaleStarted) {
      this.toggleMessageModal();
      return;
    }

    const totalSupply = await nftContract.methods.totalSupply().call();
    // console.log("total minted = ", totalSupply)
    if (totalSupply >= 4200) {
      this.toggleSoldoutModal();
      return;
    }

    const { web3, address, chainId, mintQty, cost, extraMint, presaleData } =
      this.state;

    try {
      // send transaction
      var result: any;
      if (presaleStarted && !saleStarted) {
        console.log("starting presale mint");

        // make this a modal popup
        if (!presaleData) {
          this.setState({ pendingRequest: false });
          this.togglePresaleErrorModal();
          return;
        }

        // open modal
        this.toggleModal();

        // toggle pending request indicator
        this.setState({ pendingRequest: true });

        result = await callPresaleMint(
          address,
          chainId,
          web3,
          mintQty,
          cost,
          nftContract,
          extraMint,
          presaleData
        );
      } else {
        // open modal
        this.toggleModal();

        // toggle pending request indicator
        this.setState({ pendingRequest: true });
        console.log("starting  mint");
        result = await callMint(
          address,
          chainId,
          web3,
          mintQty,
          cost,
          nftContract,
          extraMint,
          presaleData
        );
      }
      const formattedResult = {
        action: functionSig,
        result,
      };

      // display result
      this.setState({
        web3,
        pendingRequest: false,
        result: formattedResult || null,
        extraMint: false,
      });
    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
    }
  };

  public resetApp = async () => {
    console.log("resetApp");
    await this.web3Modal.clearCachedProvider();
    if (
      this.state.provider?.disconnect &&
      typeof this.state.provider.disconnect === "function"
    ) {
      await this.state.provider.disconnect();
    }
    this.setState({ ...INITIAL_STATE });

    const btn = document.getElementById("connect-btn");
    if (btn) {
      btn.style.backgroundColor = "rgba(64,153,255,0)";
    }

    this.setState({ buttonText: "CONNECT" });
    if (btn) {
      btn.innerText = "CONNECT";
    }
    // else {
    // }

    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
  };

  public render = () => {
    const {
      //   assets,
      //   address,
      //   connected,
      //   chainId,
      //   fetching,
      showModal,
      pendingRequest,
      result,
      showMessageModal,
      showSoldoutModal,
      showPresaleErrorModal,
      showGiftModal,
      totalMinted,
    } = this.state;
    return (
      <div>
        <SLayout>
          <Column maxWidth={1000} spanHeight>
            <SContent>
              <div className="Desc">
                <h1>Congratulations all you fine mfers, we did it, we're all sold out!</h1>
              </div>

              {this.state.extraMint ? (
                <div className="DescMint6">
                  <div id="free-gift-message">
                    <h1>
                      collector wallet detected! we recognize you as a real
                      collector of fine NFTs and you will receive one free fine
                      art mfer with your first mint, in addition to any other
                      bonus NFTs you receive. This free NFT can only be claimed
                      on your first purchase, but your bonus gift for buying
                      multiples of 5 can be claimed on every mint.
                    </h1>
                  </div>
                </div>
              ) : (
                ""
              )}

              <br />
            </SContent>
            <br />

            <div className="DescMint2">
              {this.state.chainId && this.state.chainId === 1 ? (
                ""
              ) : (
                <div style={{ color: "red" }}>
                  <h1>{`YOU ARE ON THE WRONG NETWORK! CHANGE TO ETHEREUM MAINNET BEFORE MINTING`}</h1>
                </div>
              )}
              <br />
              <br />
            </div>
          </Column>
          <Modal show={showModal} toggleModal={this.toggleModal}>
            {pendingRequest ? (
              <SModalContainer>
                <SModalTitle>{"Pending Transaction"}</SModalTitle>
                <SContainer>
                  <Loader />
                  <SModalParagraph>
                    {"Approve or reject transaction using your wallet"}
                  </SModalParagraph>
                </SContainer>
              </SModalContainer>
            ) : result ? (
              <SModalContainer>
                <SModalTitle>{"Transaction Approved"}</SModalTitle>
                {/* <ModalResult>{result}</ModalResult> */}
              </SModalContainer>
            ) : (
              <SModalContainer>
                <SModalTitle>
                  {"You did not confirm the transaction. Please try again."}
                </SModalTitle>
              </SModalContainer>
            )}
          </Modal>

          <Modal show={showMessageModal} toggleModal={this.toggleMessageModal}>
            <SModalContainer>
              <SModalTitle>{"coming soon mfer!"}</SModalTitle>
            </SModalContainer>
          </Modal>

          <Modal show={showSoldoutModal} toggleModal={this.toggleSoldoutModal}>
            <SModalContainer>
              <SModalTitle>{"sold out mfer!"}</SModalTitle>
            </SModalContainer>
          </Modal>

          <Modal
            show={showPresaleErrorModal}
            toggleModal={this.togglePresaleErrorModal}>
            <SModalContainer>
              <SModalTitle>
                {
                  "Sorry you aren't on the presale list. Check back soon for open sale!"
                }
              </SModalTitle>
            </SModalContainer>
          </Modal>

          <Modal show={showGiftModal} toggleModal={this.toggleGiftModal}>
            <SModalContainer className="ModalContainer">
              <SModalTitle className="DescMint5">
                {"a gift for you, mfer!"}
              </SModalTitle>
              <SModalTitle>
                {
                  " as a recognized collector, you will receive 1 free fine art mfer with your first mint… in addition to the bonus NFTs for minting at least 5. this free nft will be claimed on your first purchase, but the bonus for buying multiples of 5 can be claimed on every purchase."
                }
              </SModalTitle>
            </SModalContainer>
          </Modal>
        </SLayout>
      </div>
    );
  };
}

export default App;
