import React from 'react'
import './CollectionSlider.scss'

const CollectionSlider = () => {
  return (
 
        <div className="collectionSlider">
            <div className="collectionSlider-row"></div>
        </div>




  )
}

export default CollectionSlider