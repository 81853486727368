import React from 'react'
import './Mint.css'
import Connect3 from '../Smart/Connect3';


const Mint = () => {
  return (
    <div className='MintContainter'>

      <Connect3/>
    </div>
  )
}

export default Mint