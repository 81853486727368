// import Mint from '../components/Mint/Mint'
import { useEffect } from 'react'
import Intro from './Intro'
import Story1 from './Story1'
import Story2 from './Story2'
import Story2Caption from './Story2Caption'
import Story3 from './Story3'
import Story4 from './Story4'
import Story5 from './Story5'
import Story6Caption from './Story6Caption'
import Story6 from './Story6'
import Story7Caption from './Story7Caption'
import Story7 from './Story7'
import Story8Caption from './Story8Caption'
import Story8 from './Story8'
import Story9 from './Story9'
import Story10 from './Story10'
import Story11 from './Story11'
import Story12 from './Story12'
import Story12Caption from './Story12Caption'
import Story13Caption from './Story13Caption'
import Story13 from './Story13'
import Story14 from './Story14'
import Story14Caption from './Story14Caption'
import Story15Caption from './Story15Caption'
import Story15 from './Story15'
import Climax from './Climax'
import Outro from './Outro'

import Background2Img from "../../assets/images/Background2.png"

// import '../../assets/styles/App.css'
// import '../../assets/styles/BioSlider.scss'
// import '../../assets/styles/CollectionSlider.scss'
// import '../../assets/styles/Homepage.css'
// import '../../assets/styles/Mint.css'
// import globalCSS from '../../styles/globals.css'
// import navbarCSS from '../../styles/NavBar.css'
// import aboutCSS from '../../styles/About.css'
// import '../../styles/RoadMap.css'
import '../../styles/Story.css'

import play from '../../assets/images/play.png'
import musicPlay from '../../assets/images/musicPlay.png'
import musicPause from '../../assets/images/musicPause.png'

export default function Home() {
  useEffect(() => {
    const audio = document.getElementById("backgroundMusic");
    const musicControl = document.getElementById("musicControl");
    const imgElement = document.getElementById("musicButtonImg");

    musicControl.addEventListener("click", () => {
      if (audio.paused) {
        audio.play();
        imgElement.src = musicPlay;
      } else {
        audio.pause();
        imgElement.src = musicPause;
      }
    });

    // Add a scroll event listener to play audio when the user scrolls down the page
    window.addEventListener("scroll", () => {
      // Adjust this value as needed to determine when to play audio
      const scrollThreshold = 500;

      if (window.scrollY >= scrollThreshold && audio.paused) {
        audio.play();
        imgElement.src = musicPlay;
      }
    });
  }, []);

  return (
  <div className='DarkContainer noselect' > 
  <audio id="backgroundMusic"   loop>
        <source src='https://firebasestorage.googleapis.com/v0/b/jti-demo-93fdd.appspot.com/o/Halo.wav?alt=media&token=eb2e8b67-ffcd-449f-8eef-b13618b6cc80' type="audio/mpeg"/>
        Your browser does not support the audio element.
    </audio>
    <button className='PlayButton w-12' id="musicControl" onClick={() => {
      const audio = document.getElementById("backgroundMusic");
      // const musicControl = document.getElementById("musicControl");
      const imgElement = document.getElementById("musicButtonImg");
              if (audio.paused) {
                  audio.play();
                  imgElement.src = musicPlay
              } else {
                  audio.pause();
                  imgElement.src = musicPause
              }
    }}><img id="musicButtonImg" src={musicPause}/></button>
      <div className="overlay">
         <img  draggable="false" id="video" src={Background2Img}></img>
      </div>    
      <Intro/>    
      <Story1/>
      <div className='StoryBlock'></div>
      <Story2Caption/>
      <Story2/> 
      <Story3/> 
      <Story4/>
      <Story5/>
      <Story6Caption/>
      <Story6/>
      <Story7Caption/>
      <Story7/>
      <Story8Caption/>
      <Story8/>
      <Story9/>
      <Story10/>
      <Story11/>
      <Story12Caption/>
      <Story12/>
      <Story13Caption/>
      <Story13/>
      <Story14Caption/>
      <Story14/>
      <Story15Caption/>
      <Story15/>
      <Climax/>
      <Outro/>
  </div>
  )
}
