import { NFT_CONTRACT } from '../constants'
// import { convertStringToHex } from './bignumber'
import { formatPresaleMintTransaction, formatMintTransaction /*, sanitizeHex */} from './utilities'
// import * as list from "../../../src/controllers/providers";


export function getContract(chainId: number, web3: any) {
  const zevi = new web3.eth.Contract(
    NFT_CONTRACT[chainId].abi,
    NFT_CONTRACT[chainId].address
  )
  return zevi
}


export async function callMint(address: string, chainId: number, web3: any, mintQty: any, cost: number,  nftContract: any, extraMint: boolean, merkleProof: string[]) {
  // console.log(address, mintQty, chainId, web3)
  const tx = await formatMintTransaction(address,chainId,mintQty,web3,cost, nftContract, extraMint, merkleProof) 
  
  if(!tx){
    alert("Fatal error please try again")
    return 
  }

  const txHash = await web3._provider.request({
    method: "eth_sendTransaction",
    params: [tx],
  });


  return txHash
  
}


export async function callPresaleMint(address: string, chainId: number, web3: any, mintQty: any, cost: number,  nftContract: any, extraMint: boolean, merkleProof: string[]) {
  // console.log(address, mintQty, chainId, web3)
  // console.log("calling formatPresaleMintTx...", merkleProof)
  if(!merkleProof){
    alert("Sorr you aren't on the presale list. Check back soon!")
    return
  }
  const tx = await formatPresaleMintTransaction(address,chainId,mintQty,web3,cost, nftContract, extraMint, merkleProof) 
  
  if(!tx){
    alert("Fatal error please try again")
    return 
  }

  const txHash = await web3._provider.request({
    method: "eth_sendTransaction",
    params: [tx],
  });


  return txHash
  
}


