import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Homepage from "./components/HomepageComponent/Homepage";
import ArtPage from "./components/Artpocalypse/Index"

const root = createRoot(document.getElementById("app"));

root.render(
  <Router>
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route path="/Artpocalypse" component={ArtPage} />
      {/* Add more Route components for additional pages */}
    </Switch>
  </Router>
);