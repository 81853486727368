import { React, useRef, useEffect } from 'react'
import { FaBiohazard } from 'react-icons/fa'
import { motion,  useScroll, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import _1Img from '../../assets/images/1.png'

export default function Story1() {
   
  const {ref, inView} = useInView();
  const animation = useAnimation();

  useEffect(() => {
    
    if(inView){
      animation.start({
        y: '000px',
        opacity: 1,
        // scale: 1,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    } if (!inView){
      animation.start({
        y: '200px',
        // scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      })
    }
  }, [inView]);

  return (
    <section ref={ref}>    

        <motion.div className='StoryArt'
        animate={animation}
        >
            <img  draggable="false" src={_1Img} alt='StoryArt'/>
        </motion.div>
    </section>
  )
}

