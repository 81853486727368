import './Homepage.css'

// Page Imports 

// import Mint from '../Mint/Mint.jsx'

// Image Imports

import Header from '../../assets/images/HeaderLogo.png'
import RoadMap from '../../assets/images/Title_RoadMap.png'
import Mario from '../../assets/images/Mario.png'
import About from '../../assets/images/Title_About.png'
import WhatAreMF from '../../assets/images/WhatAreMF.png'
import Twitter from '../../assets/images/Twitter.png'
// import Etherscan from '../../assets/images/etherscan.png'
import Metavate from '../../assets/images/Metalogo.png'

// Slider Imports 

import CollectionSlider from '../Sliders/CollectionSlider'
import BioSlider from '../Sliders/BioSlider'

import Mint from '../Mint/Mint'
import EnterArt from './Enter'


function Homepage() {


  return (
    <div className="App">
      <EnterArt/>
      
{/* Header  */}

      <img src={Header} alt="Logo" className='HeaderLogo' />
      <div className='socialLinks'>
      <a href='https://twitter.com/FineartMfers' target="_blank"><img  src={Twitter} alt="twitter" className='TwitterLogo' /></a>
      {/* <a href='https://etherscan.io' target="_blank"><img  src={Etherscan} alt="twitter" className='EtherscanLogo' /></a> */}
      </div>

{/* Mint  */}


    <Mint/>


{/* Slider - Gallery  */}
<CollectionSlider/>

<div className='gap'> </div> 
<div className='gap'> </div> 

      <img src={WhatAreMF} alt="WhatAreFineArtMFers" className='WhatAreLogo' />

      <div className="Desc ">

      <h1>"fine art mfers" is a collection of 4200 hand-drawn degens by renowned artist Marlon Pruz. A tribute to the OG mfers NFT, every trait was painstakingly drawn by him over the past 5 months. No matter the rarity, each piece was designed to be a 1 of 1 masterpiece filled with Easter eggs, cultural references, and a mind-blowing amount of detail. Marlon flips the simplicity of mfers by using them as a template for these high-quality illustrations, preserving the perspective drawn by the now-departed Sartoshi. Join the curation and behold the future of mfers.</h1>

      </div>


<div className='gap'> </div> 
<div className='gap'> </div> 


{/* RoadMap  */}


    <img src={RoadMap} alt="RoadMap" className='RoadMapLogo' />


    <div className="Desc ">
    
        <h1>Proceeds of fine art mfers will be used to fund public murals and community art days for the neighborhoods that need it most. Proceeds will also be used to spread the love to other independent artists in the space by purchasing their unsold pieces and adding them to our curated collection. With these initiatives, we can introduce art and educate the public about web3, not to mention, having mfers painted on murals all over the world! Many more things are yet to come, such as companions and airdrops. I guess we just gonna have to wait and see...</h1>
         
    </div>

    <div className='gap'> </div> 
    <div className='gap'> </div> 
    
    <div className="video">
        <iframe
          style={{ border: 0 }}
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/zzyNAM9z1cE"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
    </div>

    <div className="DescMint4">
    
    <h3>MFERS JUST <br/> GETTING STARTED</h3>
     
</div>
    


{/* Biography */}



      {/* <img src={Mario} alt="MarioPruz" className='BioLogo' /> */}

      <div className='Desc'> 
      
          {/* <h1>
          Marlon Pruz is a full time illustrator and muralist from Miami Beach, Florida. His art derives from the constant influx of images and
          ideas that he conjures. expressing them through his primal need to manifest them into the world. It is a universal language that he
          uses to tap into communities and connect with people from all walks of life. Each piece is created using an explosion of detail, line,
          and depth, formulating a controlled chaos that grabs the viewer and pulls them into the work. Marlon Pruz has collaborated with huge
          brands such as Vans, Off-White, and Mercedes-Benz. As well as, public murals and art showcased all over the world.
          </h1> */}
      
      
      </div>



{/* Slider - Bio Images */}



<BioSlider/>
<div className='gap'> </div> 
<div className='gap'> </div> 





{/* About the Artist */}


  <img src={About} alt="About" className='AboutLogo' />

      <div className='Desc'> 

          <h1>
          Marlon Pruz is a full time illustrator and muralist from Miami Beach, Florida. His art derives from the constant influx of images and
          ideas that he conjures. expressing them through his primal need to manifest them into the world. It is a universal language that he
          uses to tap into communities and connect with people from all walks of life. Each piece is created using an explosion of detail, line,
          and depth, formulating a controlled chaos that grabs the viewer and pulls them into the work. Marlon Pruz has collaborated with huge
          brands such as Vans, Off-White, and Mercedes-Benz. As well as, public murals and art showcased all over the world.
          </h1>

      
          {/* <h1>
                My art derives from the constant influx of images and ideas rushing through my head and the primal need to
                manifest them into the world. It is my universal language I can use to tap into communities and connect with
                people from all walks of life. Each piece is a projection of my soul and gathered life experience projected
                to create multiple bodies of work through many different forms.&nbsp;&nbsp;Each concept takes on its own
                entity whether it being through pen and paper, fashion, animation, digital art, or a 100ft wall.
          </h1> */}
  
      </div>

    <div className='gap'> </div> 



      <div className="video">
        <iframe
          style={{ border: 0 }}
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/qiE25K_VvPk"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
    </div>



    <div className='Desc'> 
{/*       
      <h1>
            I create art based on my specific surroundings whether it be nature, the ingenuity of the buildings in
            the Favelas, the vintage art deco hotels on South Beach, or the detailed motifs in neoclassical etchings
            tied in with the situations Ive experienced while being out on the street. People watching and my
            fascination with anthropology play a major role in my works. A large part of my art is created with the
            intention of entertaining and captivating all who glance at it. My art is created using an explosion of
            detail, line, and depth, formulating a controlled chaos that grabs the viewer and pulls them into the work.
      </h1> */}

  </div>


  <div className='gap'> </div> 

  <div className="footer">
      <p className="metavate-p">Powered by</p>
      <a href="https://metavate.io" target="_blank" rel="noopener">
      <img src={Metavate} alt="Metavate" className='MetavateLogo' />
      </a>
    </div>





{/* Footer  */}

    </div>
  )
}


export default Homepage