import {React, useEffect } from 'react'
import { AiFillHome } from 'react-icons/ai'
import { motion, useScroll, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import ArtpocalypseImg from '../../assets/images/Artpocalypse.png'


export default function Welcome() {
  const {ref, inView} = useInView();
  const IntroAnimation = useAnimation();
  const IntroTextAnimation = useAnimation();

  useEffect(() => {
    
    if(inView){
      IntroAnimation.start({
        y: '0',
        opacity: 1,
        scale: [.8, 1, .8],
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0',
        scale: 1,
        opacity: 1,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    } if (!inView){
      IntroAnimation.start({
        y: '200px',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '200px',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    }
  }, [inView]);


  return (
    <section ref={ref}>
      <div className="AppContainer3">
        <div className='NavContainer'>
            <div className='NavLogo' >
            <a href='/'><AiFillHome/></a>
              <motion.div animate={IntroAnimation}>
              <img  draggable="false" className='NavLogo' src={ArtpocalypseImg}></img>
              </motion.div>
              <motion.div className='StoryCaption fontArt' animate={IntroTextAnimation}>                            
        
              <p>The Year is <span className='fontAlt'>2069</span>,  Each country is trying to one-up the others by showing that they possess the most powerful atomic weapons—a dangerous game of chicken to prove who has the biggest piece.</p>
              
              </motion.div>
            </div>
 
        </div>
      </div>
    </section>
  )
}

