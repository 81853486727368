import { React, useRef, useEffect } from 'react'
import { FaBiohazard } from 'react-icons/fa'
import { motion,  useScroll, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import _12Img from '../../assets/images/12.jpeg'

export default function Story12() {
   
  const {ref, inView} = useInView();
  const animation = useAnimation();

  useEffect(() => {
    
    if(inView){
      animation.start({
        y: '0',
        opacity: 1,
        // scale: 1,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    } if (!inView){
      animation.start({
        y: '0',
        // scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      })
    }
  }, [inView]);

  return (
    <motion.section ref={ref}  animate={animation}>    

        <div  >
        <motion.div className='StoryArt'
       
        >
            <img  draggable="false" src={_12Img} alt='StoryArt'/>
        </motion.div>
        </div>
    </motion.section>
  )
}

