import React from 'react'
import './BioSlider.scss'

const BioSlider = () => {
  return (
   
    <div className="bioSlider">
        <div className="bioSlider-row"></div>
    </div>

  )
}

export default BioSlider