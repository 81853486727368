import React, { useEffect, useState } from 'react';
import { motion, useScroll, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import popImg from "../../assets/images/pop.png"
import flickImg from "../../assets/images/flick.png"
import gif from "../../assets/images/Flick.gif"
import metalogoImg from "../../assets/images/Metalogo.png"

export default function Outro() {
  const { ref, inView } = useInView();
  const ButtonAnimation = useAnimation();
  const IntroTextAnimation = useAnimation();
  const [showModal, setShowModal] = useState(false);
  const [showBeanModal, setShowBeanModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    if (inView) {
      ButtonAnimation.start({
        y: '0',
        opacity: 1,
        scale: [.8, 1, .8],
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0',
        scale: 1,
        opacity: 1,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    } else {
      ButtonAnimation.start({
        y: '200px',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '200px',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    }
  }, [inView]);

  const openModal = (url) => {
    setRedirectUrl(url);
    setShowModal(true);
  };

  const openBeanModal = (url) => {
    setRedirectUrl(url);
    setShowBeanModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  
  const closeBeanModal = () => {
    setShowBeanModal(false);
  };
  const handleRedirect = () => {
    window.location.href = 'https://artpocalypse.fineartmfers.io';
  };
  
  const handleAlert = (message) => {
    window.alert(message);
  };
  

  return (
    <section ref={ref} className='FullScreen'>
      <div className="">
        <div className=''>
          <div className=''>
            {/* <a href='/'>Home</a> */}
            <motion.div className='px-4 StoryCaption5 fontArt' animate={IntroTextAnimation}>
              <p>Will you flick the bean?<br />Or<br />Will you pop the bean?</p>
            </motion.div>
            <motion.div className='EndButtons' animate={ButtonAnimation}>
              <div onClick={openBeanModal}>
                <img  draggable="false" className='buttons' src={flickImg} />
                {/* Comming Soon */}
              </div>
              <div onClick={openModal}>
                <img  draggable="false" className='buttons' src={popImg} />
              </div>
              {/* <div onClick={() => openModal('http://localhost:8080/fineartmfers')}><img  draggable="false" className='buttons' src="../../assets/images/pop.png" /></div> */}
            </motion.div>
          </div>
        </div>
      </div>

      {/* Redirect popup modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
         {/* <p> Coming Soon </p> */}
         {/* <button className='bg-white px-4 py-2 rounded-lg mt-8' onClick={closeModal}>Pick Again</button> */}
              
 <div className="modal-content">
              <h2>You are transferring safely</h2>
              <p>to <strong style={{ color: "#C8F600" }}>NFT Redeem</strong> to claim your mint.</p>
            
              <code style={{ color: "#C8F600", paddingBottom: "20px" }}>https://artpocalypse.fineartmfers.io</code>
              <p className='text-[.75rem]'>Click "Continue" to proceed or "Cancel" to stay on this page.</p>
              <div className="modal-buttons">
                <button className='bg-white' onClick={handleRedirect}>Continue</button>
                <button className='bg-gray-500 text-white border-[1px] border-[#FFFFFF10]' onClick={closeModal}>Cancel</button>
              </div>
            </div>
            <div className="FooterMain">
              <p className="relative top-10">Powered by</p>
              <a href="https://metavate.io" target="_blank" rel="noopener" className='flex m-auto justify-center'>
                <img  draggable="false" src={metalogoImg} alt="Metavate" className='invert' />
              </a>
        </div>
          </div>
        </div>
      )}


       {/* Redirect popup modal */}
       {showBeanModal && (
        <div className="modal-overlay">
          <div className="modal">
           {/* <p>Coming Soon</p> */}
             <img className='' src={gif} alt="go back"/>
            <button className='bg-white px-4 py-2 rounded-lg mt-8' onClick={closeBeanModal}>Pick Again</button>
          </div>
        </div>
      )}
    </section>
  );
}
