import React from "react";
import { FaBiohazard } from "react-icons/fa";
import '../../styles/Homepage.css'

import enterImage from '../../assets/images/enter.png'

export default function EnterArt() {
  return (
    <main className="MainContainer">
       <div className="EnterButton">
            <a href="/Artpocalypse">
              <img  draggable="false" src={enterImage}></img>
            </a>
          </div>
      <div className="background-container">
        
        <div className="AppContainer2">
         
        </div>
      </div>
    </main>
  );
}
