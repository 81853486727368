import {React, useEffect } from 'react'
import { FaBiohazard } from 'react-icons/fa'
import { motion, useScroll, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


export default function Story12Caption() {
  const {ref, inView} = useInView();
  const IntroAnimation = useAnimation();
  const IntroTextAnimation = useAnimation();

  useEffect(() => {
    
    if(inView){
      IntroAnimation.start({
        y: '0',
        opacity: 1,
        scale: [.8, 1, .8],
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0px',
        scale: 1,
        opacity: 1,
        transition: {
          type: 'tween', duration: .5
        }
      });
    } if (!inView){
      IntroAnimation.start({
        y: '0',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
      IntroTextAnimation.start({
        y: '0',
        scale: 0,
        opacity: 0,
        transition: {
          type: 'tween', duration: .5, bounce: 0.3
        }
      });
    }
  }, [inView]);


  return (
    <section ref={ref}>
      <div className="">
        <div className=''>
            <div className=''>
                 
              <motion.div className='px-4 StoryCaption fontArt' animate={IntroTextAnimation}>                            
        
              <p>After all the dust was settled, there was one secret corridor that was untouched by the madness. This hallway contained the last remaining, highly sought after, fine art mfers. Owned by YOU</p>
              
              </motion.div>
            </div>
 
        </div>
      </div>
    </section>
  )
}

